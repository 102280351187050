import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildLoadDrillholeValidationErrorsUrl } from '../../../network/urls';
import { convertToCamelWithSpecialParameters, sendRetrieveRequest } from '../../../utils';
import { ValidationError, ValidationErrorResponse } from '../types';

export const loadDrillholeValidationErrors = (drillholeId: string): Observable<ValidationError> => {
    const url = buildLoadDrillholeValidationErrorsUrl(drillholeId);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<ValidationErrorResponse>) => {
            const result = convertToCamelWithSpecialParameters<ValidationError>(response, {});
            return observableOf(result);
        }),
    );
};
